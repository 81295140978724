import styled from 'styled-components';

const PricingStyles = styled.nav`

.c_primary {
  color: var(--orange);
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Bebas Neue";
}

p, a {
  font-family: "Work Sans";
}

a {
  color: #FF5B39;
  text-decoration: none;
}

button {
  padding: 15px;
  margin: 10px;
  border: 0px;
  border-radius: 0.3em;
  background-color: limegreen;
  color: black;
  box-shadow: 0 2px 0 green;

  &:hover {
    background-color: green;
    color: white;
  }
}

.container {
  color: #000000;
  width: 70%;
}
.plan {
  position: relative;
  float: left;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
header {
  position: relative;
}
.plan-title {
  position: relative;
  top: 0;
  padding: 5px 15px;
  margin: 0 auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  display: inline-block;
  background-color: #222f3d;
  color: #ffffff;
  text-transform: uppercase;
}
.plan-cost {
  padding: 0px 10px 20px;
}
.plan-price {
  font-family: 'Roboto';
  font-weight: 800;
  font-size: 2.4em;
  color: #34495e;
}
.plan-type {
  opacity: 0.6;
}
.plan-features {
  padding: 0;
  margin: 0;
  list-style: outside none none;
  font-size: 0.8em;
  text-align: start;
}
.plan-features li {
  border-top: 1px solid #d2d7e2;
  padding: 10px 5%;
}
.plan-features li:nth-child(even) {
  background: rgba(0, 0, 0, 0.08);
}
.plan-features i {
  margin-right: 8px;
  opacity: 0.4;
}
.plan-select {
  border-top: 1px solid #d2d7e2;
  padding: 10px 10px 0;
}
.plan-select a {
  background-color: #222f3d;
  color: #ffffff;
  text-decoration: none;
  padding: 0.5em 1em;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
}
.plan-select a:hover {
  background-color: #46627f;
}
.featured {
  margin-top: -10px;
  background-color: var(--black);
  color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.featured .plan-title,
.featured .plan-price {
  color: #ffffff;
}
.featured .plan-cost {
  padding: 10px 10px 20px;
}
.featured .plan-features li {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
.featured .plan-select {
  padding: 20px 10px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
@media only screen and (max-width: 767px) {
  .plan {
    width: 50%;
  }
  .plan-title,
  .plan-select a {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .plan-cost,
  .featured .plan-cost {
    padding: 20px 10px 20px;
  }
  .plan-select,
  .featured .plan-select {
    padding: 10px 10px 10px;
  }
  .featured {
    margin-top: 0;
  }
}
@media only screen and (max-width: 440px) {
  .plan {
    width: 100%;
  }
}

.price-box .monthly {
	display: none;
}

.show-monthly .price-box .monthly {
	display: block;
}

.show-monthly .price-box .annually {
	display: none;
}
/* Pill toggle */
.toggle-container {
	display: none;
	align-items: center;
	justify-content: center;
}

.toggle-container label {
	background-image: linear-gradient(
		to right,
		limegreen,
		green
	);
	border-radius: 50px;
	cursor: pointer;
	display: inline-block;
	margin: 0 10px;
	position: relative;
	height: 30px;
	width: 60px;
}

.toggle-container label .ball {
	background-color: #fff;
	border-radius: 50%;
	position: absolute;
	top: 2.5px;
	left: 2.5px;
	height: 25px;
	width: 25px;
	transition: transform 0.4s ease;
}

.toggle-container input {
	position: absolute;
	left: -9999px;
  display: none;
}

.toggle-container input:checked + label .ball {
	transform: translateX(30px);
}

[dir=rtl] ul.plan-features {
  text-align: right;
}
[dir=ltr] ul.plan-features {
  text-align: left;
}

.plan-cross {
  position: relative;
  display: inline-block;
  font-weight: 800;
  font-size: 1.4em;
  font-family: 'ROBOTO';
}
.plan-cross::before, .plan-cross::after {
  content: '';
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
}
.plan-cross::before {
  border-bottom: 2px solid red;
  -webkit-transform: skewY(-30deg);
  transform: skewY(-30deg);
}
.plan-cross::after {
  border-bottom: 2px solid red;
  -webkit-transform: skewY(30deg);
  transform: skewY(30deg);
}
`;

export default PricingStyles;